<template>
    <div id="topo">
        <div id="menuIcon">
            <button
                class="btn btn-outline-light"
                @click.stop.prevent  = "onClick()"
            >
                <img 
                    class   = "h-auto max-w-full" 
                    src     = "@/assets/imagens/icones/menu.svg" 
                    title   = "Horas Máquinas"
                    width   = "40px"
                    height  = "40px"
                >
            </button>
        </div>
        <div id="menuBrasao">
            <img 
                class   = "h-auto max-w-full" 
                :src    = "getIMGPath('brasao', $store.state.municipio.municipio_logo)"
                width   = "70px"
                height  = "70px"
            >
        </div>
        <div 
            id="menuTexto" 
            v-bind:class="classObject"
        >
            <span class="h3">SGOS {{$store.state.servidor.servidor_urlnome}}</span> 
            <br>
            <span class="h4">Sistema de Gestão de Horas Máquinas</span>
        </div> 
        <div id="menuUsuario" class="mt-3 mr-2">
            <b>
            <span>{{$store.state.user.user_nome}}</span>
            </b>
            <br>
            <span>{{$store.state.user.user_nmmunicipio}}</span>
        </div>
        <div id="menuUsuarioAvatar">
            <button
                class="btn btn-outline-light"
                @click.stop.prevent  = "onClickDesconecta()"
            >
                <img 
                    class   = "h-auto max-w-full" 
                    :src    = "getIMGPath('logo', $store.state.user.user_avatar)"
                    title   = "Desconectar"
                    width   = "50px"
                    height  = "50px"
                >
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name        : 'layoutTopo', 
        components  : {
        },
        data() {
          return {
            teste : true,
            dados : [], 
          }
        },
        
        methods: {
            onClick(){
                this.$emit('ClickMenu')
            },
            getIMGPath(tipo, img) {
                if (tipo==='brasao'){
                    return img ? require("@/assets/imagens/brasao/" + img) : "";
                } else {
                    return img ? require("@/assets/imagens/icones/" + img) : "";
                }
            },
            onClickDesconecta(){
                this.$router.push('/logoff');
            }
        },  
        computed: {
            classObject: function () {
                if (this.$store.state.servidor.servidor_urltipo) {
                    return 'bg-transparent'
                } else {
                    return 'bg-warning' 
                }
        }
    }
}
</script>

<style scoped>
    #topo{
        width: 100%;
        height: 79px;
        display: flex;
    }
    #menuIcon{
        width: 65px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #menuBrasao{
        margin-left: 10px;
        width: 160px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #menuTexto{
        width: 100%;
        height: 100%;
        text-align: center;
        margin-top: 2px;
        font-family: arial, helvetica, sans-serif;
        font-weight: 300;
    }
    #menuUsuario{
        width: 350px;
        text-align: right;
    }
    #menuUsuarioAvatar{
        width: 95px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>