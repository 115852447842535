export function useLinksApi(url, nmpesquisa = ''){
    // let    mUrlBase      = 'http://127.0.0.1:8000/api/';
    let    mUrlBase      = 'https://sgsim.tec.br/public/api/';
    let    mUrlCaminho   = '';
    let    mNmPesquisa   = nmpesquisa;
    if (mNmPesquisa){ mNmPesquisa = 10;}

    //------------------------------------------
    // GARAGEM
    //------------------------------------------
      // BAIXA MANUAL
      if (url==='os.baixa.gravar.manual.hodometro')     { mUrlCaminho = 'baixa/baixa_manual_hodometro'; } 
      if (url==='os.baixa.gravar.manual.relogio')       { mUrlCaminho = 'baixa/baixa_manual_relogio'; } 
      // DEFINIR EQUIPAMENTO
      if (url==='os.equipamento_definir')               { mUrlCaminho = 'os/equipamento_definir'; } 

    //------------------------------------------
    // HORAS MAQUINAS
    //------------------------------------------
      // OS
      if (url==='os.acao')                              { mUrlCaminho = 'os/acao'; } 
      if (url==='os.detalhar')                          { mUrlCaminho = 'os/detalhar'; } 
      if (url==='os.historicoexecucao')                 { mUrlCaminho = 'os/historicoexecucao'; } 
      if (url==='os.edit')                              { mUrlCaminho = 'os/edit'; } 
      if (url==='os.lista')                             { mUrlCaminho = 'os/lista'; } 
      if (url==='os.cancelar.detalhar')                 { mUrlCaminho = 'os/cancelar_detalhar'; } 
      if (url==='os.agendaexecucao')                    { mUrlCaminho = 'os/agenda_execucao'; } 

      // OS INDIVIDUAL
      if (url==='osindividual.lista')                   { mUrlCaminho = 'osindividual/lista'; } 
      if (url==='osindividual.acao')                    { mUrlCaminho = 'osindividual/acao'; }
      if (url==='osindividual.edit')                    { mUrlCaminho = 'osindividual/edit'; }
      if (url==='osindividual.excluir')                 { mUrlCaminho = 'osindividual/excluir'; }
    //------------------------------------------
    // MUNICIPES
    //------------------------------------------
      // LOCALIDADES
      if (url==='localidades.lista')                    { mUrlCaminho = 'localidades/lista'; } 
      if (url==='localidades.acao')                     { mUrlCaminho = 'localidades/acao'; }
      if (url==='localidades.edit')                     { mUrlCaminho = 'localidades/edit'; }
      // MUNICÍPES
      if (url==='municipes.lista')                      { mUrlCaminho = 'municipes/lista'; } 
      if (url==='municipes.acao')                       { mUrlCaminho = 'municipes/acao'; }
      if (url==='municipes.edit')                       { mUrlCaminho = 'municipes/edit'; }
      if (url==='municipes.importadados')               { mUrlCaminho = 'municipes/importadados'; }
      if (url==='municipes.saldo.de.horas')             { mUrlCaminho = 'municipes/saldo_de_horas'; }
    //------------------------------------------
    // MUNICÍPIO
    //------------------------------------------
    if (url==='municipio.acao')                         { mUrlCaminho = 'municipio/acao'; }
    if (url==='municipio.edit')                         { mUrlCaminho = 'municipio/edit'; }
    //------------------------------------------
    // USUARIO
    //------------------------------------------
    if (url==='usuario.listamenu')                      { mUrlCaminho = 'usuario/listamenu';  }
    if (url==='usuario.valida')                         { mUrlCaminho = 'usuario/validausuario';  }
    if (url==='usuario.logoff')                         { mUrlCaminho = 'usuario/logoff';  }
    if (url==='usuario.lista')                          { mUrlCaminho = 'usuario/lista'; } 
    if (url==='usuario.acao')                           { mUrlCaminho = 'usuario/acao'; }
    if (url==='usuario.edit')                           { mUrlCaminho = 'usuario/edit'; }
    //------------------------------------------
    // FINANCEIRO
    //------------------------------------------
    if (url==='financeiro.gerarboletos.lista')          { mUrlCaminho = 'os_financeiro/gerar_boletos_lista'; }
    if (url==='financeiro.gerarboletos.busca')          { mUrlCaminho = 'os_financeiro/gerar_boletos_busca'; }
    if (url==='financeiro.gerarboletos.grava')          { mUrlCaminho = 'os_financeiro/gerar_boletos_gravar'; }
    if (url==='financeiro.confirmar_pagto.lista')       { mUrlCaminho = 'os_financeiro/confirmar_pagto_lista'; }
    if (url==='financeiro.confirmar_pagto.grava')       { mUrlCaminho = 'os_financeiro/confirmar_pagto_grava'; }
    //------------------------------------------
    // ALMOXARIFADO  
    //------------------------------------------
      // PRODUTOS
      if (url==='almoxarifado.produtos.lista_comb')     { mUrlCaminho = 'almoxarifado/produtoslistacombustivel'; } 
      // SETOR
      if (url==='almoxarifado.equipamentosetor.lista')  { mUrlCaminho = 'almoxarifado/equipamentosetorlista'; } 
    //------------------------------------------
    // CONFIGURAÇÕES  
    //------------------------------------------
      // IMPLEMENTOS
      if (url==='configuracoes.implemento.lista')       { mUrlCaminho = 'configuracoes/implementoslista'; } 

      // EQUIPAMENTOS
      if (url==='configuracoes.equipamento.geral')      { mUrlCaminho = 'configuracoes/equipamentosgeral'; } 
      if (url==='configuracoes.equipamento.lista')      { mUrlCaminho = 'configuracoes/equipamentoslista'; } 
      if (url==='configuracoes.equipamento.acao')       { mUrlCaminho = 'configuracoes/equipamentosacao'; }
      if (url==='configuracoes.equipamento.edit')       { mUrlCaminho = 'configuracoes/equipamentosedit'; }

      // GRUPO DE EQUIPAMENTOS
      if (url==='configuracoes.grupoequip.lista')       { mUrlCaminho = 'configuracoes/grupoequiplista'; } 
      if (url==='configuracoes.grupoequip.acao')        { mUrlCaminho = 'configuracoes/grupoequipacao'; }
      if (url==='configuracoes.grupoequip.edit')        { mUrlCaminho = 'configuracoes/grupoequipedit'; }
      if (url==='configuracoes.grupoequip.dados')       { mUrlCaminho = 'configuracoes/grupoequipdados'; }

      // OPERADORES  
      if (url==='configuracoes.operador.lista')         { mUrlCaminho = 'configuracoes/operadorlista'; }
      if (url==='configuracoes.operador.acao')          { mUrlCaminho = 'configuracoes/operadoracao'; }
      if (url==='configuracoes.operador.edit')          { mUrlCaminho = 'configuracoes/operadoredit'; }
      if (url==='operador.buscaequipamento')            { mUrlCaminho = 'configuracoes/operadorbuscaequipamento'; }

      // REGIÃO
      if (url==='configuracoes.regiao.lista')           { mUrlCaminho = 'configuracoes/regiaolista'; }
      if (url==='configuracoes.regiao.acao')            { mUrlCaminho = 'configuracoes/regiaoacao'; }
      if (url==='configuracoes.regiao.edit')            { mUrlCaminho = 'configuracoes/regiaoedit'; }
      // SERVICOS  
      if (url==='configuracoes.servicos.lista')         { mUrlCaminho = 'configuracoes/servicoslista'; }
      if (url==='configuracoes.servicos.acao')          { mUrlCaminho = 'configuracoes/servicosacao'; }
      if (url==='configuracoes.servicos.edit')          { mUrlCaminho = 'configuracoes/servicosedit'; }
      if (url==='configuracoes.servicos.dados')         { mUrlCaminho = 'configuracoes/servicosdados'; }
    //------------------------------------------
    if (url==='pdf.exibicao')                           { mUrlCaminho = 'pdf/pdf'; }

    let    mCaminho        = mUrlBase+mUrlCaminho;
    return mCaminho;
}
